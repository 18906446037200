<template>
  <div>

    <b-form @submit.stop.prevent="formOnsubmit">

      <!-- Name input -->
      <b-form-group
        id="input-group-name"
        label="Nama:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama..."
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- price -->
      <b-form-group
        id="input-group-price"
        label="Harga:"
        label-for="input-price"
      >
        <b-form-input
          id="input-price"
          v-model="formatedPrice"
          inputmode="numeric"
          placeholder="Harga..."
        ></b-form-input>
        <small class="text-danger">{{ error.price }}</small>
      </b-form-group>

      <!-- Lab Category -->
      <b-form-group
        id="input-group-lab-category"
        label="Kategori Lab:"
        label-for="input-lab-category"
      >
        <b-form-select
          id="input-lab-category"
          v-model="form.lab_category_id"
          :options="labCategories"
        ></b-form-select>
        <small class="text-danger">{{ error.lab_category_id }}</small>
      </b-form-group>

      <!-- doctor commission -->
      <b-form-group
        id="input-group-doctor-commission"
        :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
        label-for="input-doctor-commission"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              squared
              @click="btnIsPercentToggle"
              class="bg-light-primary font-weight-bold text-primary"
            >{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
          </template>
          <b-form-input
            id="input-doctor-commission"
            :readonly="formatedPrice == 0"
            @keyup="doctorCommisionOnKeyUp"
            v-model="formatedDoctorCommission"
            placeholder="Komisi Dokter..."
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.doctor_commission }}</small>
      </b-form-group>

      <!-- hospital income -->
      <b-form-group
        id="input-group-hospital-income"
        :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
        label-for="input-hospital-income"
      >
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              squared
              @click="btnIsPercentToggle"
              class="bg-light-primary font-weight-bold text-primary"
            >{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
          </template>
          <b-form-input
            id="input-hospital-income"
            :readonly="formatedPrice == 0"
            @keyup="hospitalIncomeOnKeyUp"
            v-model="formatedHospitalIncome"
            placeholder="Pendapatan Rumah Sakit..."
          ></b-form-input>
        </b-input-group>
        <small class="text-danger">{{ error.hospital_income }}</small>
      </b-form-group>

      <!-- description -->
      <b-form-group id="input-group-description">
        <label for="input-description">Deskripsi <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-group-description"
          v-model="form.description"
          placeholder="Deskripsi..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button
        type="submit"
        variant="primary"
      >Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/lab-procedure/list')"
      >
        Batal
      </b-button>

    </b-form>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    form: Object,
    route: String
  },

  data() {
    return {
      // Error
      error: {
        name: '',
        price: '',
        description: '',
        lab_category_id: '',
        doctor_commission: '',
        hospital_income: '',
      },
      // Options
      labCategories: [],
      // other
      totalPercentage: 100,
      isPercent: false
    }
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString('id-ID')
        } else {
          this.form.price = ''
        }
      },
      get() {
        return this.form.price
      }
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString('id-ID')
        } else {
          this.form.doctor_commission = ''
        }
      },
      get() {
        return this.form.doctor_commission
      }
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString('id-ID')
        } else {
          this.form.hospital_income = ''
        }
      },
      get() {
        return this.form.hospital_income
      }
    }
  },

  methods: {

    async getLabCategoryOptions() {
      let response = await module.setSelectOption('lab-categories')
      this.labCategories = response.data
      this.labCategories.unshift({ text: 'Pilih Kategori Lab', value: '', disabled: true })
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = ''
      this.error.hospital_income = ''
      this.totalPercentage = 100
      this.form.doctor_commission = ''
      this.form.hospital_income = ''
      this.isPercent ? this.isPercent = false : this.isPercent = true
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.form.doctor_commission
          this.form.hospital_income = this.totalPercentage
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
          this.form.hospital_income = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.form.hospital_income
          this.form.doctor_commission = this.totalPercentage
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)
          this.form.doctor_commission = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    async formOnsubmit() {
      console.log('submit')
      if (!this.isPercent) {
        let intPrice = parseInt(this.form.price.replace(/\D/g, ''), 10)
        let intDoctorCommission = parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
        let intHospitalIncome = parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)

        
        let amount_commision = intDoctorCommission + intHospitalIncome
        
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          console.log('int price < intdoctor')
          Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga', 'warning')
        } else {
          if(amount_commision <= intPrice){
            this.actualFormSubmit()
          }else{
            Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga', 'warning')
          }
        }
      } else {
        this.actualFormSubmit()
      }
    },

    async actualFormSubmit() {
      // Make Request
      this.form['is_percent'] = this.isPercent;
      
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/lab-procedure/list')
      }
    }

  },

  mounted() {    
    this.getLabCategoryOptions()
  }

}
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>